'use client';
import React from 'react';
import Image from 'next/image';

import Flag from '@/components/ui/flag/flag';
import { LinkButton } from '@/components/ui/link-button';

import { CheckBulletIcon } from '@/assets/br/sections/our-advantages';

import { IOurAdvantages } from './ourAdvantages.interfaces';

export const OurAdvantages: React.FC<IOurAdvantages> = ({ props }) => {
  return (
    <section className="bg-black px-8 pb-5 pt-4 lg:relative lg:py-10 lg:pt-8">
      <div className="container mx-auto lg:hidden">
        <Image
          className="relative w-full rounded-2xl"
          src={props.card.backgroundImage.url}
          width={1000}
          height={1000}
          alt={props.card.backgroundImage.alt}
          title={props.card.backgroundImage.title}
        />
        <div className="relative -mt-9 flex flex-col gap-4 rounded-2xl bg-white p-4 text-black">
          <Flag product="totalfit" />
          <h2 className="text-[32px] font-medium">{props.card.title}</h2>
          <div className="flex flex-col gap-2">
            {props.advantages.map((item, index) => (
              <div key={index} className="flex gap-2">
                <Image
                  src={CheckBulletIcon.src}
                  alt="Check bullet icon"
                  title="Check bullet icon"
                  width={16}
                  height={16}
                />
                <p>{item}</p>
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-medium">{props.card.titleExclusivePartners}</p>
            <div className="flex gap-4 md:hidden">
              {props.exclusivePartners.slice(0, 4).map((item, index) => (
                <div key={index} className="flex flex-col items-center gap-1">
                  <Image
                    src={item.image}
                    alt={`${item.name} icon`}
                    title={`${item.name} icon`}
                    width={32}
                    height={32}
                  />
                  <p className="text-[10px] font-medium">{item.name}</p>
                </div>
              ))}
            </div>
            <div className="hidden md:flex md:gap-4">
              {props.exclusivePartners.map((item, index) => (
                <div key={index} className="flex flex-col items-center gap-1">
                  <Image
                    src={item.image}
                    alt={`${item.name} icon`}
                    title={`${item.name} icon`}
                    width={32}
                    height={32}
                  />
                  <p className="text-[10px] font-medium">{item.name}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-medium">{props.card.titleOtherPartners}</p>
            <div className="flex gap-4 md:hidden">
              {props.otherPartners &&
                props.otherPartners.slice(0, 4).map((item, index) => (
                  <div key={index} className="flex flex-col items-center gap-1">
                    <Image
                      src={item.image}
                      alt={`${item.name} icon`}
                      title={`${item.name} icon`}
                      width={32}
                      height={32}
                    />
                    <p className="text-[10px] font-medium">{item.name}</p>
                  </div>
                ))}
            </div>
            <div className="hidden md:flex md:gap-4">
              {props.otherPartners &&
                props.otherPartners.map((item, index) => (
                  <div key={index} className="flex flex-col items-center gap-1">
                    <Image
                      src={item.image}
                      alt={`${item.name} icon`}
                      title={`${item.name} icon`}
                      width={32}
                      height={32}
                    />
                    <p className="text-[10px] font-medium">{item.name}</p>
                  </div>
                ))}
            </div>
          </div>
          <LinkButton
            className="rounded-[42px] border-none font-normal"
            variant="primary"
            href={props.card.button.link}
          >
            {props.card.button.text}
          </LinkButton>
        </div>
      </div>
      <div className="container mx-auto px-8">
        <div
          className="hidden w-full justify-end overflow-hidden rounded-3xl lg:flex"
          style={{
            backgroundImage: `url(${props.card.backgroundImage.url})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
          aria-label={props.card.backgroundImage.alt}
          title={props.card.backgroundImage.title}
        >
          <div className="relative my-6 mr-6 flex max-w-[700px] flex-col gap-4 rounded-2xl bg-white p-4 text-black">
            <Flag product="totalfit" />
            <h2 className="text-[32px] font-medium">{props.card.title}</h2>
            <div className="flex flex-col gap-2">
              {props.advantages.map((item, index) => (
                <div key={index} className="flex gap-2">
                  <Image
                    src={CheckBulletIcon.src}
                    alt="Check bullet icon"
                    title="Check bullet icon"
                    width={16}
                    height={16}
                  />
                  <p>{item}</p>
                </div>
              ))}
            </div>
            <div className="flex flex-col gap-2">
              <p className="font-medium">{props.card.titleExclusivePartners}</p>
              <div className="flex gap-4">
                {props.exclusivePartners.map((item, index) => (
                  <div key={index} className="flex flex-col items-center gap-1">
                    <Image
                      src={item.image}
                      alt={`${item.name} icon`}
                      title={`${item.name} icon`}
                      width={32}
                      height={32}
                    />
                    <p className="text-[10px] font-medium">{item.name}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <p className="font-medium">{props.card.titleOtherPartners}</p>
              <div className="flex gap-4">
                {props.otherPartners &&
                  props.otherPartners.map((item, index) => (
                    <div key={index} className="flex flex-col items-center gap-1">
                      <Image
                        src={item.image}
                        alt={`${item.name} icon`}
                        title={`${item.name} icon`}
                        width={32}
                        height={32}
                      />
                      <p className="text-[10px] font-medium">{item.name}</p>
                    </div>
                  ))}
              </div>
            </div>
            <LinkButton
              className="rounded-[42px] border-none font-normal"
              variant="primary"
              href={props.card.button.link}
            >
              {props.card.button.text}
            </LinkButton>
          </div>
        </div>
      </div>
    </section>
  );
};
