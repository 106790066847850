'use client';
import React, { useState } from 'react';
import * as AccordionPrimitives from '@radix-ui/react-accordion';
import { AccordionTriggerProps } from './accordion-trigger.interfaces';

export const AccordionTrigger: React.FC<AccordionTriggerProps> = ({ children, isopen, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);

  const isOpenVar = isopen === 'true' ? true : false;

  const textColor = isOpenVar ? 'text-primary-40' : 'text-white';

  return (
    <AccordionPrimitives.Trigger
      className="group flex w-full items-center gap-2 pb-1.5 pt-3.5 group-hover:text-primary-40"
      style={{ stroke: '#26D07C' }}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      {...props}
    >
      <div className="flex flex-1">
        <h4 className={`text-left text-lg ${textColor} group-hover:text-primary-40`}>{children}</h4>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className="transition-transform duration-300 ease-[cubic-bezier(0.87,_0,_0.13,_1)] hover:text-primary-40 group-data-[state=open]:rotate-180"
        style={{
          stroke: isopen || isHovered ? '#26D07C' : 'white',
          transition: 'fill 0.3s',
        }}
      >
        <path d="M6 9L12 15L18 9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </AccordionPrimitives.Trigger>
  );
};
