/* eslint-disable prettier/prettier */
'use client';
import React, { useState } from 'react';
import { usePathname } from 'next/navigation';

import Accordion from '@/components/ui/accordion';
import { LinkButton } from '@/components/ui/link-button';
import { Button } from '@/components/ui/button';

import { scrollToTop } from '@/functions/scrollToTop';

import { IFaqProps } from './faq.interfaces';

export const Faq: React.FC<IFaqProps> = ({ scroll, props }) => {
  const locale = usePathname()?.includes('/mx/');

  const [openItem, setOpenItem] = useState<string | null>(null);

  const handleItemClick = (value: string) => {
    setOpenItem((prevItem) => (prevItem === value ? null : value));
  };

  return (
    <section className="px-6">
      <div className="container mx-auto">
        <div className="flex justify-center md:mx-8">
          <div className="flex w-full flex-col">
            <h2 className="text-center text-2xl font-bold text-white md:text-3xl">{props.title}</h2>
            <div className="mt-6">
              <Accordion defaultValue="">
                {props.faq.map((item, index) => {
                  const isOpenAccordion = openItem === `question-${index}` ? 'true' : 'false';

                  return (
                    <Accordion.Item key={index} value={`question-${index}`}>
                      <Accordion.Trigger onClick={() => handleItemClick(`question-${index}`)} isopen={isOpenAccordion}>
                        {item.question}
                      </Accordion.Trigger>
                      <Accordion.Content isopen={isOpenAccordion}>
                        <p className="text-sm text-white" dangerouslySetInnerHTML={{ __html: item.firstParagraph }} />
                        {item.secondParagraph && <p className="text-sm text-white" dangerouslySetInnerHTML={{ __html: item.secondParagraph }} />}
                      </Accordion.Content>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </div>
            <div className="flex flex-col justify-center gap-6 pb-10 pt-4 md:flex-row md:gap-8 lg:pb-20 lg:pt-10">
              {scroll ? (
                <Button className="w-full md:w-auto" onClick={scrollToTop} variant="primary">
                  {props.button.text}
                </Button>
              ) : (
                props.button.link && (
                  <LinkButton href={props.button.link} target="_self" variant="primary" className="w-full md:w-auto">
                    {props.button.text}
                  </LinkButton>
                )
              )}
              <LinkButton
                href={`${locale
                  ? 'https://ayuda.totalpass.com.mx/hc/es-mx?_ga=2.15689014.1622739210.1723468558-1716738027.1718818712&_gl=1*ydfnhh*_gcl_au*MTEzMTE5NDQzMy4xNzE4ODE4NzEw*_ga*MTcxNjczODAyNy4xNzE4ODE4NzEy*_ga_CCP4G0JJDT*MTcyMzczNjQyNS45LjEuMTcyMzczNjQzMi41My4wLjA.'
                  : 'https://ajuda.totalpass.com.br/hc/pt-br'
                  }`}
                target="_blank"
                variant="secondary"
                className="w-full md:w-auto"
              >
                {locale ? 'Ir al centro de ayuda' : 'Acessar central de ajuda'}
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
