'use client';
import React from 'react';
import Image from 'next/image';
import { usePathname } from 'next/navigation';

import { LinkButton } from '@/components/ui/link-button';

import Arrow from '@/assets/icons/white-right-arrow.svg';

import { IGymSearch } from './gymSearch.interfaces';

export const GymSearch: React.FC<IGymSearch> = ({ props }) => {
  const locale = usePathname()?.includes('/mx/');

  return (
    <section className="bg-[#F3F3F6]">
      <div className="container mx-auto">
        <div className="flex flex-col gap-4 px-8 py-5 md:flex-row md:gap-5 lg:py-11">
          <div
            className="flex min-h-[264px] flex-col rounded-2xl px-6 py-4 text-white md:w-1/2 md:justify-end"
            style={{
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.75) 24.16%, rgba(0, 0, 0, 0.00) 93.5%), url(${props.firstCard.image.url}) -134.634px -87.14px / 190.319% 126.895% no-repeat lightgray`,
              backgroundSize: 'cover',
              backgroundPosition: props.firstCard.imagePosition ? 'initial' : 'center',
            }}
            aria-label={props.firstCard.image.alt}
            title={props.firstCard.image.title}
          >
            <h2 className="mb-4 mt-12 max-w-xs text-[44px] font-semibold leading-10">{props.firstCard.title}</h2>
            {props.firstCard.button && (
              <LinkButton
                className="flex w-2/3 gap-2 px-0 md:w-full md:justify-normal"
                href={props.firstCard.button.link}
                variant="tertiary"
                target="_blank"
              >
                <p>{props.firstCard.button.text}</p>
                <Image alt="Ícone de uma seta branca apontando para a direita" src={Arrow} width={24} height={24} />
              </LinkButton>
            )}
          </div>
          <div
            // eslint-disable-next-line prettier/prettier
            className={`flex flex-col items-center gap-6 rounded-2xl p-6 text-center md:w-1/2 lg:px-24 lg:py-24 ${locale || props.secondCard.greenColor ? 'bg-primary-40 text-black' : 'bg-black text-white'
              // eslint-disable-next-line prettier/prettier
              }`}
          >
            <h3 className="text-2xl font-medium">{props.secondCard.title}</h3>
            <p>{props.secondCard.description}</p>
            {props.secondCard.button && (
              <LinkButton
                className="max-w-[250px]"
                href={props.secondCard.button.link}
                variant={locale || props.secondCard.greenColor ? 'quaternary' : 'secondary'}
                target="_blank"
              >
                {props.secondCard.button.text}
              </LinkButton>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
