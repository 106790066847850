'use client';
import Image from 'next/image';
import React from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { usePathname } from 'next/navigation';

import Flag from '@/components/ui/flag/flag';

import { IBenefits } from './benefits.interfaces';

import 'swiper/css';
import 'swiper/css/pagination';

export const Benefits: React.FC<IBenefits> = ({ title, benefitItem }) => {
  const path = usePathname();

  const locale = path?.includes('/mx/');

  return (
    <section className="pl-8 pt-8 md:relative md:py-10 md:pb-5 md:pl-0">
      <div className="container mx-auto">
        <div className="lg:px-8">
          <div className="mb-6 flex max-w-[90%] flex-col gap-4 lg:mb-10 lg:max-w-[60%] lg:gap-8">
            <h2 className=" text-2xl font-semibold text-white  lg:text-5xl">{title}</h2>
          </div>
          <div className="flex overflow-hidden xl:hidden">
            <Swiper centeredSlides={true} modules={[Pagination]} spaceBetween={8} width={350}>
              <div className="m-40">
                {benefitItem &&
                  benefitItem.map((item, index) => (
                    <SwiperSlide className="flex flex-col" key={index}>
                      <div className="relative h-40 w-full overflow-hidden rounded-t-3xl">
                        {
                          // eslint-disable-next-line @next/next/no-img-element
                          <img
                            src={item.image}
                            alt={item.imageAlt}
                            title={item.imageTitle}
                            style={{ objectFit: 'cover' }}
                          />
                        }
                      </div>
                      <div
                        // eslint-disable-next-line prettier/prettier
                        className={`rounded-b-3xl border-t-2 border-[#545454] bg-[#353535] p-4 text-white ${locale ? 'flex h-72 flex-col justify-between' : ''
                          // eslint-disable-next-line prettier/prettier
                          }`}
                      >
                        <Flag product={item.flag} />
                        <p className="mb-4 mt-2 text-sm lg:text-lg">{item.description}</p>
                        <div className="flex gap-6">
                          {item.products.map((product, index) => (
                            <div key={index} className="flex flex-col items-center gap-1">
                              <Image
                                src={product.image}
                                alt={product.title}
                                width={40}
                                height={40}
                                className="rounded-full"
                              />
                              <p className="max-w-[80%] text-center text-xs">{product.title}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </div>
            </Swiper>
          </div>
          <div className="hidden xl:grid xl:grid-cols-3 xl:gap-5">
            {benefitItem &&
              benefitItem.map((item, index) => (
                <div className="flex h-full flex-col" key={index}>
                  <div className="relative h-64 overflow-hidden rounded-t-3xl">
                    {
                      // eslint-disable-next-line @next/next/no-img-element
                      <img
                        src={item.image}
                        alt={item.imageAlt}
                        title={item.imageTitle}
                        style={{ objectFit: 'cover' }}
                      />
                    }
                  </div>
                  <div className="rounded-b-3xl border-t-2 border-[#545454] bg-[#353535] text-white md:p-4 lg:flex lg:flex-1 lg:flex-col lg:justify-between lg:p-6">
                    <Flag product={item.flag} />
                    <p className="md: mt-2 md:mb-4 md:text-sm lg:mb-6 lg:mt-4 lg:text-lg">{item.description}</p>

                    <div className="flex gap-6">
                      {item.products.map((product, productIndex) => (
                        <div key={productIndex} className="flex flex-col items-center gap-1">
                          <Image
                            src={product.image}
                            alt={product.title}
                            width={40}
                            height={40}
                            className="rounded-full"
                          />
                          <p className="max-w-[80px] text-center text-xs lg:max-w-none">{product.title}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};
