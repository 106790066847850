'use client';
import { Accordion } from './accordion';
import { AccordionContent } from './accordion-content/accordion-content';
import { AccordionItem } from './accordion-item/accordion-item';
import { AccordionTrigger } from './accordion-trigger/accordion-trigger';

export default Object.assign(Accordion, {
  Content: AccordionContent,
  Item: AccordionItem,
  Trigger: AccordionTrigger,
});
