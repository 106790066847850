'use client';
import React from 'react';
import Image from 'next/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';

import { LinkButton } from '@/components/ui/link-button';
import { SwiperButtonPrev, SwiperButtonNext } from '@/components/ui/swiper-button';

import Arrow from '@/assets/icons/black-right-arrow.svg';

import { IModalities } from './modalities.interfaces';

import 'swiper/css';
import 'swiper/css/pagination';
import '@/styles/newSwiper.css';
import '@/styles/swiperModalities.css';

export const Modalities: React.FC<IModalities> = ({ modalitiesProps }) => {
  return (
    <section className="overflow-hidden pt-8 lg:pl-16">
      <div className="container mx-auto">
        <div className="flex flex-col gap-4 md:flex-row">
          <div className="mx-6 max-h-[250px] justify-between rounded-2xl bg-primary-40 px-6 py-4 md:mx-0 md:content-end">
            <h2 className="mb-4 text-3xl font-semibold">{modalitiesProps.title}</h2>
            <LinkButton
              className="flex w-2/3 justify-normal gap-2 px-0 hover:text-black md:w-full"
              href={modalitiesProps.button.link}
              variant="tertiary"
              target="_blank"
            >
              <p>{modalitiesProps.button.text}</p>
              <Image alt="Ícone de uma seta branca apontando para a direita" src={Arrow} width={24} height={24} />
            </LinkButton>
          </div>
          <div>
            <Swiper
              modules={[Pagination, Navigation]}
              pagination={{
                clickable: true,
              }}
              spaceBetween={16}
              width={250}
              data-test="swiper-slide"
            >
              <div>
                {modalitiesProps.image.map((item, index) => (
                  <SwiperSlide
                    className="flex flex-col content-end first:ml-8 md:first:ml-0"
                    style={{ height: '250px' }}
                    key={index}
                  >
                    {
                      // eslint-disable-next-line @next/next/no-img-element
                      <img
                        className="h-2/3 w-full rounded-t-2xl"
                        src={item.url}
                        alt={item.alt}
                        title={item.title ?? ''}
                      />
                    }
                    <div className="h-1/3 content-center rounded-b-2xl bg-white p-4 text-lg font-semibold">
                      <h3>{item.modality}</h3>
                    </div>
                  </SwiperSlide>
                ))}
              </div>
              <div className="hidden md:flex md:gap-4 md:py-4">
                <SwiperButtonPrev />
                <SwiperButtonNext />
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};
