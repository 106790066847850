'use client';
import React from 'react';

import * as AccordionPrimitives from '@radix-ui/react-accordion';
import { AccordionProps } from './accordion.interfaces';

export const Accordion: React.FC<AccordionProps> = ({ defaultValue, ...props }) => {
  return (
    <AccordionPrimitives.Root
      className="flex flex-col divide-y divide-neutral-40"
      type="single"
      defaultValue={defaultValue}
      collapsible
      {...props}
    />
  );
};
