export { default as BioRitmoIcon } from './bio-ritmo.svg';
export { default as BuddhaSpaIcon } from './buddha-spa-icon.svg';
export { default as CheckBulletIcon } from './check-bullet-icon.svg';
export { default as JabHouseIcon } from './jab-house-icon.svg';
export { default as OurAdvantagesImage } from './our-advantages-image.webp';
export { default as OaziIcon } from './oazi-icon.svg';
export { default as RacebootcampIcon } from './race-bootcamp.svg';
export { default as SkyFitIcon } from './sky-fit-icon.webp';
export { default as SmartFitIcon } from './smart-fit.svg';
export { default as TecfitIcon } from './tecfit.svg';
export { default as TonusGymIcon } from './tonus-gym.svg';
export { default as VidyaIcon } from './vidya-icon.svg';
export { default as Velocity } from './velocity.svg';
export { default as Kore } from './kore.svg';
