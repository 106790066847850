'use client';
import React from 'react';
import { Marquee } from '@/components/ui/marquee';

import { OurPartnersSectionProps } from '@/components/sections/our-partners/our-partners.interfaces';

export const OurClients: React.FC<OurPartnersSectionProps> = ({ partners, title }) => {
  return (
    <section className="space-y-4 bg-black pb-5 pt-8 lg:py-10">
      <div className="container mx-auto">
        <h3 className="px-8 text-sm text-white lg:text-base lg:font-medium">{title}</h3>
      </div>
      <div className="flex w-full">
        <Marquee>
          {partners &&
            partners.logos.map((item, index) => (
              // eslint-disable-next-line @next/next/no-img-element
              <img className="mx-3" key={index} src={item.url} alt={item.alt} title={item.alt} width={70} height={70} />
            ))}
        </Marquee>
      </div>
    </section>
  );
};
